import { call, put, takeEvery, select } from "redux-saga/effects";
import axios from "axios";
import {API as api} from "../../api";
import {  token } from "../auth/selectors";
import { GET_LAB_FAILURE, GET_LAB_REQUEST, GET_LAB_SUCCESS } from "./types";
//Replace endpoint lab request!!!!!!!!!!!!!!! 
function labgetAxios(token_access) {
    return axios({
      method: "GET",
      url: `${api}/api/`,
      params: {
        "access-token": token_access,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }
  
  function* getLab() {
    try {
      const token_access = yield select(token);
      const lab = yield call(labgetAxios, token_access);
      yield put({
        type: GET_LAB_SUCCESS,
        lab: lab,
      });
  
    } catch (e) {
      yield put({
        type: GET_LAB_FAILURE,
        message: e ? String(e.message) : "Error de conexión",
      });
    }
  }

  export default function* labSaga() {
    yield takeEvery(GET_LAB_REQUEST, getLab);
  }