import produce from "immer";
import { getLoadMatrizE, getSimulateMatrizE } from "./actions";
import { initialState } from "./states";
import * as types from "./types";

export const matrizE = (state = initialState, { payload, type }) =>
  produce(state, (draft) => {
    switch (type) {
      case getLoadMatrizE.TRIGGER:
        draft.action = payload;
        draft.loading = true;
        break;
      case getLoadMatrizE.REQUEST:
        break;
      case getLoadMatrizE.SUCCESS:
        const columns = payload.env[0].values.map((item) => item.date);

        let rows = [];
        for (const key in payload.data) {
          if (Object.hasOwnProperty.call(payload.data, key)) {
            const element = payload.data[key];
            rows.push(element);
          }
        }

        var part = rows.slice(0, rows.length > 15 ? 15 : rows.length);
        const matrizE = {
          columns: columns,
          env: payload.env,
          response: part,
        };

        if (!draft.matrizE || draft.action === "first") {
          draft.simulation = matrizE;
        }

        draft.matrizE = matrizE;
        break;
      case getLoadMatrizE.FAILURE:
        draft.response.error = payload.error;
        break;
      case getLoadMatrizE.FULFILL:
        draft.loading = false;
        break;

      case getSimulateMatrizE.TRIGGER:
        draft.loading = true;
        draft.action = payload;
        break;
      case getSimulateMatrizE.REQUEST:
        break;
      case getSimulateMatrizE.SUCCESS:
        break;
      case getSimulateMatrizE.FAILURE:
        draft.response.error = payload.error;
        break;
      case getSimulateMatrizE.FULFILL:
        draft.loading = false;
        break;
      case types.SET_FORM_DATA_MATRIZ_E:
        if (payload.many) {
          for (const change of payload.changes) {
            draft.formData[change.propertyName] = change.propertyValue;
          }
        } else {
          draft.formData[payload.propertyName] = payload.propertyValue;
        }

        break;
      case types.SET_DEFAULT_FORM_DATA_MATRIZ_E:
        const categorySelected = payload.category;
        const maxDate = categorySelected
          ? new Date(categorySelected.last_month.split("-").join("/"))
          : new Date();
        const minDate = new Date(
          categorySelected.first_month.split("-").join("/")
        );
        maxDate.setFullYear(maxDate.getFullYear() - 1);
        draft.formData.subcategoryId = categorySelected.subcategory_ids[0].id;
        draft.formData.maxDate = maxDate;
        draft.formData.minDate = minDate;
        draft.formData.period = minDate;
        draft.formData.month = 1;

        if (payload.shouldClear) {
          draft.simulationData = initialState.simulationData;
          draft.matrizE = null;
          draft.simulation = null;
        }

        break;
      case types.CLEAR_SIMULATE_MATRIZ_E:
        if (payload) {
          draft.simulationData[payload] = [];
          if (draft.simulation) {
            draft.matrizE = draft.simulation;
          }
        } else {
          draft.simulationData = initialState.simulationData;
          draft.matrizE = null;
          draft.simulation = null;
        }

        break;
      case types.CLEAR_RESPONSE_MATRIZ_E:
        draft.response = { error: null, message: null, result: null };
        break;

      case types.SET_SIMULATION_DATA_MATRIZ_E:
        const code = payload.id;
        let itemExist = draft.simulationData[payload.propertyName].find(
          (item) => item.code === payload.index
        );
        if (itemExist) {
          let periodExists = itemExist.items.find(
            (period) => period.code === code
          );
          if (periodExists) {
            periodExists.value = payload.propertyValue;
          } else {
            itemExist.items.push({
              code: code,
              value: payload.propertyValue,
            });
          }
        } else {
          draft.simulationData[payload.propertyName].push({
            code: payload.index,
            items: [{ code: code, value: payload.propertyValue }],
          });
        }
        break;
    }
  });
