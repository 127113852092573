import axios from "axios";

export const API = "https://pricing.demo4to.com";

export const callApiAxios = (method, path, token, params, body) => {
  try {
    let headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    if (token) {
      headers = { ...headers, Authorization: token };
    }

    switch (method) {
      case "POST-PARAMS":
        return axios({
          method: "POST",
          url: `${API}/api/${path}`,
          params: params,
          headers: headers,
        })
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            throw error.response?.data;
          });
        break;

        case "PATCH":
          return axios({
            method: "PATCH",
            url: `${API}/api/${path}`,
            params: params,
            data: JSON.stringify(body),
            headers: headers,
          })
            .then((response) => {
              return response.data;
            })
            .catch((error) => {
              throw error.response?.data;
            });
          break;

      case "POST":
        return axios({
          method: "POST",
          url: `${API}/api/${path}`,
          params: params,
          data: JSON.stringify(body),
          headers: headers,
        })
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            throw error.response?.data;
          });
        break;

      case "GET":
        return axios({
          method: "GET",
          url: `${API}/api/${path}`,
          params: params,
          headers: headers,
        })
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            throw error.response?.data;
          });
        break;

      case "PUT":
        return axios({
          method: "PUT",
          url: `${API}/api/${path}`,
          params: params,
          data: JSON.stringify(body),
          headers: headers,
        })
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            throw error.response?.data;
          });
        break;

      case "DELETE":
        return axios({
          method: method,
          url: `${API}/api/${path}`,
          params: params,
          headers: headers,
        })
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            throw error.response?.data;
          });
        break;
      default:
        break;
    }
  } catch (error) {
  }
};
