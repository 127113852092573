/* eslint-disable default-case */
import produce from "immer";
import { getLoadCurves, getSimulateCurves } from "./actions";
import * as types from "./types";
import { initialState } from "./states";
import { contDots, formatNumber, truncateNumber } from "../../../../Utils";

export const curves = (state = initialState, { payload, type }) =>
  produce(state, (draft) => {
    switch (type) {
      case getLoadCurves.TRIGGER:
        draft.loading = true;
        draft.simulatorData.priceSc2 = 0;
        draft.simulatorData.variablesSc2 = [];
        break;
      case getLoadCurves.REQUEST:
        break;
      case getLoadCurves.SUCCESS:
        draft.curves = [payload];
        if (draft.curves && draft.curves.length > 0) {
          draft.simulatorData.priceSc1 = formatNumber(
            draft.curves[0][draft.formData.functionType].user_point.price,
            true
          );
          draft.simulatorData.priceSc2 = draft.simulatorData.priceSc1;
          draft.simulatorData.variablesSc1 = draft.curves[0].env_values.map(
            (variable) => {
              try {
                if (!isNaN(variable.value)) {
                  const newNumber = parseFloat(variable.value);
                  return {
                    ...variable,
                    value: formatNumber(newNumber, true),
                  };
                }
              } catch (error) {
                return {
                  ...variable,
                  value: 0,
                };
              }
            }
          );
          draft.simulatorData.variablesSc2 = draft.simulatorData.variablesSc1;
          if (draft.curves.length > 1) {
            draft.simulatorData.priceSc2 = formatNumber(
              draft.curves[1][draft.formData.functionType].user_point.price,
              true
            );
            draft.simulatorData.variablesSc2 = draft.curves[1].env_values.map(
              (variable) => {
                try {
                  if (!isNaN(variable.value)) {
                    const newNumber = parseFloat(variable.value);
                    return {
                      ...variable,
                      value: formatNumber(newNumber, true),
                    };
                  }
                } catch (error) {
                  return {
                    ...variable,
                    value: 0,
                  };
                }
              }
            );
          }
        }
        break;
      case getLoadCurves.FAILURE:
        draft.response.error = payload.error;
        break;
      case getLoadCurves.FULFILL:
        draft.loading = false;
        break;
      case getSimulateCurves.TRIGGER:
        draft.loading = true;
        if (payload.priceSc2) {
          draft.simulatorData.priceSc2 = payload.priceSc2;
        }
        draft.simulatorData.variablesSc2 = payload.variablesSc2.map(
          (variable) => {
            try {
              if (!isNaN(variable.value)) {
                const newNumber = parseFloat(variable.value);
                return {
                  ...variable,
                  value: formatNumber(newNumber, true),
                };
              }
            } catch (error) {
              return {
                ...variable,
                value: 0,
              };
            }
          }
        );
        break;
      case getSimulateCurves.REQUEST:
        break;
      case getSimulateCurves.SUCCESS:
        draft.curves = payload;
        break;
      case getSimulateCurves.FAILURE:
        draft.response.error = payload.error;
        break;
      case getSimulateCurves.FULFILL:
        draft.loading = false;
        break;
      case types.SET_FORM_DATA_SIMULATION:
        draft.formData[payload.propertyName] = payload.value;
        break;
      case types.SET_AXIS_GRAPH:
        draft.xyAxis = payload;
        break;
      case types.SET_TYPE_FUNCTION:
        draft.formData.functionType = payload;
        if (draft.curves && draft.curves.length > 0) {
          draft.simulatorData.priceSc1 =
            draft.curves[0][draft.formData.functionType].user_point.price;
        } else {
          draft.simulatorData.priceSc1 = 0;
        }

        break;
      case types.SET_DEFAULT_FORM_DATA_CURVES:
        const categorySelected = payload.category;
        draft.formData.subcategoryId = categorySelected.subcategory_ids[0].id;
        draft.formData.skuId = categorySelected.subcategory_ids[0].sku_ids[0];

        if (payload.shouldClear) {
          draft.curves = [];
        }
        break;
      case types.SET_SIMULATOR_DATA:
        let isValidValue = true;
        let newValue = payload.propertyValue;
        if (["variablesSc1", "variablesSc2"].includes(payload.propertyName)) {
          const variables = draft.simulatorData[payload.propertyName];
          for (let index = 0; index < newValue.length; index++) {
            const variableExist = variables[index];
            if (variables.length > 0 && variableExist) {
              let value = newValue[index]["value"];
              const totalDots = contDots(value);
              if (variableExist.value !== value) {
                newValue[index]["value"] = newValue[index]["value"].replace(
                  ",",
                  ""
                );
                if (totalDots === 1) {
                  newValue[index]["value"] = value.substring(
                    0,
                    value.indexOf(".") + 3
                  );
                }
                isValidValue = totalDots <= 1 ? true : false;
              }
            } else {
              newValue[index]["value"] = newValue[index]["value"].replace(
                ",",
                ""
              );
              isValidValue = contDots(newValue) <= 1 ? true : false;
            }
          }
        } else {
          newValue = newValue.replace(",", "");
          if (contDots(newValue) === 1) {
            newValue = newValue.substring(0, newValue.indexOf(".") + 3);
          }
          isValidValue = contDots(newValue) <= 1 ? true : false;
        }

        if (isValidValue) {
          draft.simulatorData[payload.propertyName] = newValue;
        }
        break;
      case types.CLEAR_CURVES_SIMULATION:
        draft.curves = [];

        break;
      case types.CLEAR_CURVES_SIMULATION_DATA:
        if (draft.curves.length > 1) {
          draft.curves[1] = null;
        }

        draft.simulatorData.variablesSc2 = [];
        draft.simulatorData.priceSc2 = "";
        break;

      case types.CLEAR_RESPONSE_CURVES:
        draft.response = { error: null, message: null };
        break;
    }
  });
