import { Typography } from "@mui/material";
import { Menu, MenuItem } from "@mui/material/";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createFileName, useScreenshot } from "use-react-screenshot";
import ButtonItemColumn from "../../../../Components/Button/ButtonItemColumn";
import {
  DownloadIcon,
  SaveIcon,
  ViewIcon,
} from "../../../../Components/CustomIcons";
import { commaSeparateNumber, exportToExcel } from "../../../../Utils";
import {
  CustomGrid,
  DivColor,
  DivGradient,
  DivValue,
  TitleBox,
} from "./Styles";

export default function GridMatrizE({
  data,
  onHandleExpandGridMatriz,
  showHeader,
}) {
  const [t, i18n] = useTranslation("global");
  const gridRef = useRef(null);
  const [anchorMenuDownload, setAnchorMenuDownload] = useState(null);
  const isOpenMenuDownload = Boolean(anchorMenuDownload);
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpg",
    quality: 1.0,
  });
  const [isEllipsisName, setIsEllipsisName] = useState(true);
  const [idEllipsisName, setIdEllipsisName] = useState("");

  const download = (image, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const handleDownloadExcel = () => {
    try {
      let trHead = "<tr><th> %Δ Q |  %Δ P</th>";

      for (let index = 0; index < data.length; index++) {
        trHead += `<th>${data[index].name}</th>`;
      }
      trHead += `</tr>`;
      let trBody = ``;
      for (let index = 0; index < data.length; index++) {
        trBody += `<tr>`;
        const element = data[index];
        for (let index2 = 0; index2 < data.length; index2++) {
          const item = data[index2];
          let value = element.values[item.id] || "";
          value = value !== "" ? commaSeparateNumber(value.toFixed(2)) : "";
          trBody +=
            index2 === 0
              ? `<td>${data[index].name}</td><td>${value}</td>`
              : `<td>${value}</td>`;
        }
        trBody += `</tr>`;
      }
      let excelTableHTML = `<table><thead>${trHead}</thead><tbody>${trBody}</tbody></table>`;
      let excelTable = document.createElement("table");
      excelTable.innerHTML = excelTableHTML;
      exportToExcel("matriz-e", excelTable);
    } catch (error) {}
  };

  const handleDownloadImage = () => {
    const downloadScreenshot = () =>
      takeScreenShot(gridRef.current).then(download);
    downloadScreenshot();
  };

  const handleOpenDownloadMenu = (event) => {
    setAnchorMenuDownload(event.currentTarget);
  };

  const handleCloseDownloadMenu = () => {
    setAnchorMenuDownload(null);
  };

  const handleEllipsis = (bol, index, isCol) => {
    setIsEllipsisName(bol);
    setIdEllipsisName(`${index}-${isCol}`);
  };

  const buildText = (element, isEllipsis) => {
    return element.name.length > 33 && !isEllipsis
      ? `${element.name.substring(0, 30)}...`
      : element.name;
  };

  const buildTitlesMatriz = (isCol) => {
    return data.map((element, index) => {
      let isEllipsis = isEllipsisName && idEllipsisName === `${index}-${isCol}`;
      let fontSize = 1;
      let whiteSpace = !isEllipsis ? "nowrap" : "unset";
      let overflow = !isEllipsis ? "unset" : "hidden";

      const colsNum = data.length;
      if (colsNum > 0 && colsNum < 6) {
        fontSize = 1.7;
        whiteSpace = "unset";
        overflow = "unset";
        isEllipsis = true;
      } else if (colsNum > 5 && colsNum < 10) {
        fontSize = 1.2;
        whiteSpace = "unset";
        overflow = "unset";
        isEllipsis = true;
      }
      const fontSizeBox = `${fontSize}rem`;
      return (
        <DivColor
          type={isCol ? "Horizontal" : "Vertical"}
          color={isCol ? "background" : "secondary"}
          backgroundColor={isCol ? "secondary" : "primary"}
          colsNum={colsNum}
          widthBox={widthBox}
          style={{
            gridRow: isCol ? index + 2 : 1,
            gridColumn: isCol ? 1 : index + 2,
          }}
          onMouseEnter={() => handleEllipsis(true, index, isCol)}
          onMouseLeave={() => handleEllipsis(false, "", isCol)}
          isEllipsis={isEllipsis}
        >
          <div
            style={{
              display: "grid",
              fontSize: fontSizeBox,
              whiteSpace: whiteSpace,
              overflow: overflow,
            }}
            className="content"
          >
            {buildText(element, isEllipsis)}
          </div>
        </DivColor>
      );
    });
  };

  const buildHighligths = (value, highligths) => {
    let indexOf = highligths.indexOf(value);
    return indexOf > -1 && indexOf <= 1 ? "" : "Ligth";
  };

  const buildMatriz = () => {
    let highligthsBuilded = 0;
    return data.map((element, index) => {
      highligthsBuilded = 0;
      let highligths = [];
      for (const key in element.values) {
        if (Object.hasOwnProperty.call(element.values, key)) {
          const val = element.values[key];
          highligths.push(val);
        }
      }
      highligths = highligths.sort((a, b) => b - a);

      return data.map((item, index2) => {
        let backgroundColor = "background";
        let color = "background";
        let value = element.values[item.id] || "";
        let styleText = "Ligth";
        let fontSize = 1;
        const colsNum = data.length;
        if (colsNum > 0 && colsNum < 6) {
          fontSize = 2.3;
        } else if (colsNum > 5 && colsNum < 10) {
          fontSize = 1.3;
        }
        const fontSizeBox = `${fontSize}rem`;

        if (item.id !== element.id && highligthsBuilded < 2) {
          styleText = buildHighligths(value, highligths);
          if (styleText === "") {
            highligthsBuilded++;
          }
        }
        if (value) {
          value = value.toFixed(2);
          backgroundColor =
            item.id === element.id ? "secondary" : `primaryVariant${styleText}`;
          color = item.id === element.id ? "background" : "secondary";
        }

        return (
          <DivValue
            backgroundColor={backgroundColor}
            color={color}
            widthBox={widthBox}
            style={{
              gridRow: index + 2,
              gridColumn: index2 + 2,
            }}
          >
            <div
              style={{
                display: "grid",
                fontSize: fontSizeBox,
                fontWeight: 600,
              }}
              className="content"
            >
              {commaSeparateNumber(value)}
            </div>
          </DivValue>
        );
      });
    });
  };

  const width = data.length > 0 ? 730 / data.length : 50;
  const widthBox = `${width}px`;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems:"center",
        rowGap: "10x",
      }}
    >
      {showHeader && (
        <TitleBox>
          <Typography
            sx={{
              color: (theme) => theme.palette.custom.secondary,
              paddingTop: "3px",
              paddingLeft: "10px",
          
            }}
            fontSize={18}
            fontWeight={700}
          >
            {t(`price_management.sniper_module.matriz_component.matriz.title`)}
          </Typography>
          <div style={{ display: "flex", paddingRight: "10px" }}>
            <ButtonItemColumn
              onClick={onHandleExpandGridMatriz}
              tooltip={t("components_globals.button_expand")}
              icon={
                <ViewIcon
                  className="iconButtonSecondary strokeLigth"
                  sx={{ fontSize: "2rem" }}
                />
              }
            />
            <ButtonItemColumn
              onClick={() => {}}
              tooltip={t("components_globals.button_save")}
              icon={
                <SaveIcon
                  className="iconButtonSecondary strokeLigth"
                  sx={{
                    fontSize: "2rem",
                  }}
                />
              }
            />
            <ButtonItemColumn
              onClick={handleOpenDownloadMenu}
              tooltip={t("components_globals.button_download")}
              icon={
                <DownloadIcon
                  sx={{
                    fontSize: "1.5rem",
                  }}
                  className="iconButtonSecondary strokeLigth"
                />
              }
            />
            <Menu
              id={`download-menu-`}
              anchorEl={anchorMenuDownload}
              open={isOpenMenuDownload}
              onClose={handleCloseDownloadMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleDownloadImage}>Download Image</MenuItem>
              <MenuItem onClick={handleDownloadExcel}>Download Excel</MenuItem>
            </Menu>
          </div>
        </TitleBox>
      )}
      <CustomGrid
        numCols={data.length}
        numRows={data.length}
        widthBox={widthBox}
        className={`gridMatriz`}
        ref={gridRef}
      >
        <div
          style={{
            display: "grid",
            justifyContent: "center",
            alignItems: "center",
            gridColumn: 1,
            gridRow: 1,
          }}
        >
          <DivGradient></DivGradient>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridTemplateRows: "1fr 1fr",
            justifyContent: "center",
            alignItems: "center",
            gridColumn: 1,
            gridRow: 1,
          }}
        >
          <Typography
            sx={{
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
              gridColumn: 2,
              gridRow: 1,
              color: (theme) => theme.palette.custom.secondary,
              fontWeight: 700,
              fontSize: "2.8rem",
            }}
          >
            %Δ P
          </Typography>
          <Typography
            sx={{
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
              gridColumn: 1,
              gridRow: 2,
              color: "white",
              fontWeight: 700,
              fontSize: "2.8rem",
            }}
          >
            %Δ Q
          </Typography>
        </div>
        {buildTitlesMatriz(true)}
        {buildTitlesMatriz(false)}
        {buildMatriz()}
      </CustomGrid>
    </div>
  );
}
