import * as type from "./types";
import { initialState } from "./states";
export default function lab(state = initialState, action) {
    switch (action.type) {
        case type.GET_LAB_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case type.GET_LAB_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                lab: action.lab.data,
            };
        case type.GET_LAB_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.message,
                lab: [],
            };
        default:
            return state;
    }
}