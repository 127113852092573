import {
  AccordionSummary,
  accordionSummaryClasses,
  Box,
  Paper,
  Switch,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Table, { tableClasses } from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export const Wrapper = styled("div")(() => ({
  boxShadow: "0px 3px 6px #00000029 ",
  borderRadius: "10px",
}));

export const CustomModalBoxExpand = styled(Paper)(({ theme }) => ({
  position: "fixed",
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: theme.palette.custom.secondary,
  overflowY: "auto",

  "& .close-btn": {
    display: "flex",
    gap: "1rem",
    color: theme.palette.custom.primary,
    position: "absolute",
    top: "1em",
    right: "1em",
    cursor: "pointer",
  },
}));

export const TitleBox = styled("div")(
  ({ theme, backgroundColor = "text" }) => ({
    boxShadow: "0px 3px 6px #00000029 ",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    minWidth: "1030px",
    padding: "10px",
    backgroundColor: `${theme.palette.custom[backgroundColor]}`,
  })
);

export const CustomGrid = styled("div")(
  ({ style, theme, numCols = 1, numRows = 1, widthBox = "50px" }) => ({
    ...style,
    display: "grid",
    gridTemplateColumns: `300px repeat(${numCols},${widthBox})`,
    gridTemplateRows: `300px repeat(${numRows},${widthBox})`,
  })
);

export const DivGradient = styled("div")(({ style, theme }) => ({
  borderRadius: "20px",
  width: "300px",
  height: "300px",

  border: "1px solid white",
  background: `linear-gradient(to bottom left, ${theme.palette.custom.primary} 0%, ${theme.palette.custom.primary} 50%, ${theme.palette.custom.secondary} 50%, ${theme.palette.custom.secondary} 100%)`,
}));

export const DivColor = styled("div")(
  ({
    style,
    theme,
    backgroundColor = "primary",
    color = "background",
    type,
    isEllipsis,
    widthBox = "50px",
    fontSizeBox = "1.5rem",
  }) => ({
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
    width: type === "Horizontal" ? "300px" : widthBox,
    height: type === "Vertical" ? "300px" : widthBox,
    backgroundColor: `${theme.palette.custom[backgroundColor]}`,
    borderRadius: "10px",
    border: "1px solid white",
    "& .content": {
      color: `${theme.palette.custom[color]}`,
      // rotate: type === "Vertical" && "-90deg",
      transform: type === "Vertical" && "rotateZ(-90deg)",
      fontSize: fontSizeBox,

      width: "280px",
      maxWidth: "280px",

      // textOverflow: "ellipsis",
    },
  })
);

export const DivValue = styled("div")(
  ({
    style,
    theme,
    backgroundColor = "background",
    color = "background",
    type,
    widthBox = "50px",
  }) => ({
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
    width: widthBox,
    height: widthBox,
    border: `1px dashed ${theme.palette.custom.text}`,
    backgroundColor:
      backgroundColor === "primaryVariantLigth"
        ? "#46937880"
        : `${theme.palette.custom[backgroundColor]}`,
    "& .content": {
      color: `${theme.palette.custom[color]}`,
    },
  })
);

export const CustomSwitch = styled(Switch)(
  ({ theme, optionTrue, optionFalse }) => ({
    width: 200,
    height: 50,
    padding: 0,
    borderRadius: 30,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 120,
        height: 50,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        color: theme.palette.custom.thirdVariant,
        transform: "translateX(90px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 0,
      "&.Mui-checked": {
        transform: "translateX(80px)",
        color: theme.palette.custom.thirdVariant,
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: theme.palette.custom.text,
        },
        "& .MuiSwitch-thumb": {
          "&:after": {
            content: `"${optionFalse}"`,
          },
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 120,
      height: 50,
      borderRadius: 30,
      color: theme.palette.custom.thirdVariant,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
      "&:after": {
        position: "absolute",
        top: "15px",
        left: "45px",
        width: 40,
        height: 20,
        color: "#fff",
        content: `"${optionTrue}"`,
      },
    },
    "& .MuiSwitch-track": {
      borderRadius: 30,
      opacity: 1,
      backgroundColor: theme.palette.custom.text,
      boxSizing: "border-box",

      "&:before, &:after": {
        position: "absolute",
        top: "15px",
        width: 40,
        height: 20,
        color: "#fff",
      },
      "&:after": {
        left: "40px",

        content: `"${optionTrue}"`,
      },
      "&:before": {
        right: "30px",

        content: `"${optionFalse}"`,
      },
    },
  })
);

export const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.custom.secondary,
  borderRadius: "10px",

  [`.${accordionSummaryClasses.expanded}`]: {
    margin: "5px 0px",
  },
  [`&.${accordionSummaryClasses.expanded}`]: {
    borderRadius: "10px 10px 0px 0px",
  },
}));

export const CustomBox = styled(Box)(({ theme, backgroundColor }) => ({
  boxShadow: "0px 3px 6px #00000029 ",
  borderRadius: "10px",
  padding: "30px",
  backgroundColor: `${theme.palette.custom[backgroundColor]}`,
}));

export const ButtonsCell = styled(TableCell)(
  ({
    theme,
    backgroundColor = "background",
    minWidth = "150px",
    maxWidth = "150px",
  }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.custom[backgroundColor],
      padding: 0,
      border: "none",
      minWidth: minWidth,
      maxWidth: maxWidth,
      height: "35px",
      left: 0,
      position: "sticky",
      zIndex: 120,
    },
  })
);

export const TitleCell = styled(TableCell)(
  ({ theme, color = "primary", type, minWidth = "100px" }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: theme.palette.custom[color],
      border: "none",
      fontWeight: "bold",
      padding: 0,
      minWidth: minWidth,
      textAlign: "center",
    },
    [`&.${tableCellClasses.head} .space-cell`]: {
      display: "grid",
      placeContent: "center",
      borderRadius: "10px",
      backgroundColor: theme.palette.custom.primary,
      color: theme.palette.custom.secondary,
      height: "35px",
      fontSize: "0.8rem",
      marginInline: "auto",
    },
    [`&.${tableCellClasses.head} .first-cell`]: {
      display: "grid",
      placeContent: "center",
      textAlign: "center",
      backgroundColor: theme.palette.custom.background,
      height: "35px",
      fontSize: "0.8rem",
      minWidth: "300px",
      marginInline: "auto",
    },
  })
);
export const TitleGrayCell = styled(TableCell)(({ theme, type }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.custom.secondary,
    border: "none",
    fontWeight: "bold",
    padding: 0,
    minWidth: "100px",
  },
  [`&.${tableCellClasses.head} .space-cell`]: {
    display: "grid",
    placeContent: "center",
    borderRadius: "10px",
    textAlign: "center",
    backgroundColor: theme.palette.custom.primary,
    height: "65px",
    fontSize: "3rem",
  },
  [`&.${tableCellClasses.head} .space-gray`]: {
    backgroundColor: "#cdcace",
    display: "grid",
    textAlign: "center",
    placeContent: "center",
    height: "40px",
    borderRadius: "10px",
  },
  [`&.${tableCellClasses.head} .noBorder`]: {
    border: "none",
  },
}));

export const CustomTable = styled(Table)(({ theme }) => ({
  borderCollapse: "separate",
  borderSpacing: "0px  3px",
  [`&.${tableClasses.root}`]: {
    [`tr td:first-child`]: {
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
    },
    [`tr td:last-child`]: {
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  },
}));

export const StyledTableRowHead = styled(TableRow)(({ theme }) => ({
  background: `${theme.palette.custom.secondary}`,
  color: `${theme.palette.custom.primary}`,
  height: "3.5rem",
}));

export const ArrowCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.custom.secondary,
    borderBottomLeftRadius: "10px",
    borderTopLeftRadius: "10px",
    padding: 0,
    border: "none",
    borderBottomRightRadius: "10px",
    borderTopRightRadius: "10px",
  },
  [`&.${tableCellClasses.body} button`]: {
    color: theme.palette.custom.primary,
  },
  [`&.${tableCellClasses.body} .title-row`]: {
    color: theme.palette.custom.primary,
    border: "none",
    fontWeight: "bold",
    padding: 0,
    height: "35px",
  },
}));

///

export const CustomCell = styled(TableCell)(
  ({
    theme,
    type,
    minWidth = "100px",
    maxWidth = "100px",
    backgroundColor = "background",
  }) => ({
    [`&.${tableCellClasses.body}`]: {
      minWidth: minWidth,
      maxWidth: maxWidth,
      border: "none",
      padding: 0,
      paddingBlock: "6px",
      paddingLeft: type === "large" ? "5px" : "",
      textAlign: type === "large" ? "left" : "center",
      fontWeight: type === "large" ? "bold" : "",
      backgroundColor: `${theme.palette.custom[backgroundColor]}`,
    },
  })
);

export const CustomCellSticky = styled(TableCell)(
  ({
    theme,
    type,
    backgroundColor = "background",
    minWidth = "300px",
    maxWidth = "100px",
  }) => ({
    [`&.${tableCellClasses.body}`]: {
      minWidth: minWidth,
      maxWidth: maxWidth,
      border: "none",
      padding: 0,
      paddingBlock: "6px",
      paddingLeft: type === "large" ? "5px" : "",
      textAlign: type === "large" ? "left" : "center",
      fontWeight: type === "large" ? "bold" : "",
      left: 0,
      position: "sticky",
      backgroundColor: `${theme.palette.custom[backgroundColor]}`,
    },
  })
);

export const ColorCell = styled(TableCell)(
  ({
    theme,
    minWidth = "100px",
    backgroundColor,
    position,
    fontWeight,
    textAlign = "center",
  }) => ({
    [`&.${tableCellClasses.body}`]: {
      minWidth: minWidth,
      border: "none",
      paddingLeft: "15px",
      textAlign: textAlign,
      fontWeight: fontWeight ? fontWeight : "",
      left: 0,
      position: position ? position : "",
      background: theme.palette.custom[backgroundColor],
    },
  })
);

export const CustomTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "transparent",
}));
