import TextField from "../../../../Components/TextField";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material/";
import MonthPicker from "../../../../Components/MonthPicker";
import { monthDiff } from "../../../../Utils";
import Button from "../../../../Components/Button";
import {
  getLoadCurves,
  setFunctiontype,
} from "../../../../Redux/modules/what-if/curves/actions";
import { setFormDataSimulation } from "../../../../Redux/modules/what-if/curves/actions";
import { useDispatch, useSelector } from "react-redux";
import { getCategorySelected, getMinMaxDateCategorySelected } from "../../../../Redux/modules/categories/selectors";
import {
  getCurvesFormData,
  getCurvesSubcategoryId,
} from "../../../../Redux/modules/what-if/curves/selectors";
import { useCategorySelected } from "../../../../Hooks/useCategorySelected";

const FormCurves = () => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");
  const tempCurves = useSelector(getCurvesFormData);
  const subcategoryId = useSelector(getCurvesSubcategoryId);
  const {maxDate, minDate} = useSelector(getMinMaxDateCategorySelected);
  const categorySelected = useCategorySelected({ subcategoryId });
  const { skus,  subcategory, subcategories } =
    categorySelected;

  return categorySelected.id ? (
    <div>
      <Grid
        container
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          alignItems: "center",
          gap: "0.5rem",
          marginBottom: "1rem",
        }}
      >
        <TextField
          fullWidth
          select
          variant="third"
          label={t("initial_setup.forms.common_inputs.subcategory")}
          value={tempCurves?.subcategoryId}
          onChange={(e) => {
            const selected =
              subcategories &&
              subcategories.find((x) => x.id === e.target.value);
            dispatch(
              setFormDataSimulation({
                propertyName: "subcategoryId",
                value: e.target.value,
              })
            );
            dispatch(
              setFormDataSimulation({
                propertyName: "skuId",
                value: selected?.sku_ids[0],
              })
            );
          }}
        >
          {subcategories &&
            subcategories.map((option, index) => {
              return (
                <MenuItem key={index} value={option.id}>
                  {option.name}
                </MenuItem>
              );
            })}
        </TextField>
        <TextField
          fullWidth
          select
          variant="third"
          label={t("initial_setup.sku")}
          value={tempCurves?.skuId}
          onChange={(e) => {
            dispatch(
              setFormDataSimulation({
                propertyName: "skuId",
                value: e.target.value,
              })
            );
          }}
        >
          {skus &&
            skus.map((option, index) => {
              return (
                <MenuItem key={index} value={option.id}>
                  {`${option.brand_id[1]} ${option.name} ${
                    option.unit_weight
                  } ${option.uom_id ? option.uom_id[1] : ""}${
                    option.package ? "/" + option.package : ""
                  }`}
                </MenuItem>
              );
            })}
        </TextField>

        <MonthPicker
          label={t("price_management.curves_component.month_simulate")}
          views={["year", "month"]}
          value={tempCurves?.period > maxDate ? minDate : tempCurves?.period}
          onChange={(e) => {
            const month = 24 - monthDiff(e, tempCurves.maxDate);
            dispatch(
              setFormDataSimulation({
                propertyName: "month",
                value: month,
              })
            );
            dispatch(
              setFormDataSimulation({
                propertyName: "period",
                value: e,
              })
            );
          }}
          disabled={!tempCurves.period}
          maxDate={maxDate}
          minDate={minDate}
          renderInput={(params) => <TextField variant="third" {...params} />}
        />
      </Grid>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <div style={{ width: "33%" }}>
          <Button
            onClick={() => {
              dispatch(
                getLoadCurves.trigger({
                  skuId: tempCurves.skuId,
                  month: tempCurves.month,
                })
              );
            }}
            disabled={
              !tempCurves.subcategoryId ||
              !tempCurves.skuId ||
              !tempCurves.month
            }
          >
            {t("components_globals.button_run")}
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default FormCurves;
