import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Collapse,
} from "@mui/material/";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSelector } from "react-redux";
import { getSimulationLoaded } from "../../../../Redux/modules/what-if/optimal-prices/selectors";
import {
  HeadingCell,
  CustomCell,
  ColorCell,
  ArrowCell,
  CustomCellSticky,
  ArrowCellSticky,
} from "./StyleTable";
import { commaSeparateNumber } from "../../../../Utils";

export const RowCategorySku = ({ row, qtyType }) => {
  const [open, setOpen] = useState(false);
  const simulationOptimals = useSelector(getSimulationLoaded);

  const buildRow = (sku, index) => {
    const buildTable = (items, index) => {
      return (
        <>
          <CustomCell>
            {commaSeparateNumber(items["price"][index].toFixed(2))}
          </CustomCell>
          <CustomCell>
            {commaSeparateNumber(items[qtyType][index].toFixed(2))}
          </CustomCell>
          <CustomCell>
            {commaSeparateNumber(items["value"][index].toFixed(2))}
          </CustomCell>
          <CustomCell>
            {commaSeparateNumber(items["profit"][index].toFixed(2))}
          </CustomCell>
        </>
      );
    };

    return (
      <TableRow key={sku.id}>
        <CustomCellSticky type="large">{sku.name}</CustomCellSticky>
        <CustomCell>{sku.unit_weight.toFixed(0)}</CustomCell>
        <CustomCell>{sku.elasticity.toFixed(2)}</CustomCell>
        {buildTable(sku.optimals, 0)}
        {buildTable(sku.optimals, 1)}
        {buildTable(sku.optimals, 2)}
      </TableRow>
    );
  };

  return (
    <>
      <TableRow
        className="expand-tr"
        sx={{ border: "none", "& > *": { borderBottom: "unset" } }}
      >
        <ArrowCellSticky>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <span className="title-row">{row.name}</span>
        </ArrowCellSticky>
        <HeadingCell>Ag</HeadingCell>
        <HeadingCell>
          <span className="space-cell">{row.elasticity.toFixed(2)}</span>
        </HeadingCell>
        <ColorCell colSpan={12}></ColorCell>
      </TableRow>
      <TableRow className="collapse-tr">
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingInline: 0,
            border: "none",
          }}
          colSpan={15}
        >
          <Collapse className="collapse" in={open} timeout="auto" unmountOnExit>
            <Table className="nestedTable">
              <TableBody>
                {row.sku_ids?.map((sku, index) => buildRow(sku, index))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
